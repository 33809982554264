import React from "react";
import About from "../components/Top/About";
import Fv from "../components/Top/Fv";
import Service from "../components/Top/Service";
import Company from "../components/Top/Company";
import Contact from "../components/Top/Contact";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

const Top: React.FC = () => {
  return (
    <div className="">
      <ScrollToTopOnMount />
      <Fv />
      <About />
      <Service />
      <Company />
      <Contact />
    </div>
  );
};

export default Top;
