import React from "react";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <div className="bg-gray-900 text-white text-center py-2 px-4">
      <div className="flex flex-col justify-center items-center sm:flex-row sm:justify-between">
        <div>
          <h1 className="text-lg font-bold">合同会社LINKS</h1>
          <p className="text-sm font-light">Website/WebApplication</p>
        </div>
        <div className="flex justify-around gap-1">
          <Link
            to={`/`}
            className="block py-1 px-3 sm:hover:bg-white sm:hover:text-black"
          >
            Top
          </Link>
          <Link
            to={`/service`}
            className="block py-1 px-3 sm:hover:bg-white sm:hover:text-black"
          >
            Service
          </Link>
          <Link
            to={`/company`}
            className="block py-1 px-3 sm:hover:bg-white sm:hover:text-black"
          >
            Company
          </Link>
          <Link
            to={`/contact`}
            className="block py-1 px-3 sm:border sm:border-white sm:rounded-sm sm:hover:bg-white sm:hover:text-black"
          >
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
