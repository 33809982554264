import React from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className="bg-gray-900 text-white p-8 pb-2">
      <div className="flex flex-col items-center sm:flex-row sm:justify-between">
        <nav>
          <Link
            to={`/`}
            className="py-2 px-4 hover:border-b-2 hover:border-white"
          >
            Top
          </Link>
          <Link
            to={`/service`}
            className="py-2 px-4  hover:border-b-2 hover:border-white"
          >
            Service
          </Link>
          <Link
            to={`/company`}
            className="py-2 px-4 hover:border-b-2 hover:border-white"
          >
            Company
          </Link>
          <Link
            to={`/contact`}
            className="py-2 px-4 hover:border-b-2 hover:border-white"
          >
            Contact
          </Link>
        </nav>
        <nav>
          <Link
            to={`/privacy`}
            className="py-2 px-4 hover:border-b-2 hover:border-white"
          >
            Privacy Policy
          </Link>
        </nav>
      </div>
      <p className="text-center pt-4">copyright LINKS LLC.</p>
    </div>
  );
};

export default Footer;
