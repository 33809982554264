import React from "react";
import Form from "../components/Form";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

const Contact: React.FC = () => {
  return (
    <div className="bg-black min-h-[90vh] text-white">
      <ScrollToTopOnMount />
      <div className="page-fv h-[35vh] bg-contact bg-no-repeat bg-center bg-cover">
        <div className="w-[100%] h-[100%] bg-black bg-opacity-80">
          <div className="flex flex-col justify-center items-center h-[100%]">
            <p className="text-4xl font-bold mb-4">Contact</p>
            <h2>お問合せ</h2>
          </div>
        </div>
      </div>
      <section className="container mx-auto py-16 px-4">
        <p className="text-center">
          以下のフォームからお気軽にお問合せください。
        </p>
        <Form />
      </section>
    </div>
  );
};

export default Contact;
