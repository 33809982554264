import React from "react";
import Contact from "../components/Top/Contact";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import { useInView } from "react-intersection-observer";
import "animate.css";

const Service: React.FC = () => {
  const { ref, inView } = useInView({
    // オプション
    rootMargin: "100px", // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });

  return (
    <div className="bg-black min-h-[90vh] text-white">
      <ScrollToTopOnMount />
      <div className="page-fv h-[35vh] bg-service bg-no-repeat bg-center bg-cover">
        <div className="w-[100%] h-[100%] bg-black bg-opacity-80">
          <div className="flex flex-col justify-center items-center h-[100%]">
            <p className="text-4xl font-bold mb-4">Service</p>
            <h2>事業内容</h2>
          </div>
        </div>
      </div>
      <section className="container mx-auto py-16 px-4">
        <div ref={ref} className="title">
          <h3 className="text-2xl font-semibold mb-2">Webサイト制作</h3>
          <p>Web site</p>
        </div>
        {inView && (
          <div className="flex flex-col sm:flex-row justify-center items-center my-8">
            <div className="image_area w-[100%] sm:w-1/2 sm:mr-[-20px] animate__animated animate__fadeInLeft">
              <img src="./img/website.jpg" alt="" />
            </div>
            <div className="message_area w-[100%] sm:w-1/2 p-4 sm:ml-[-20px] animate__animated animate__fadeInRight">
              <p className="p-4 bg-black">
                Webサイトはオンライン上の名刺と言っても過言ではない存在になりました。
                <br />
                そして、Webサイトは企業や事業主にとっては「24時間働く営業マン」といえるでしょう。
                <br />
                企業を知ってもらう、サービスや商品を知ってもらう手段として最も重要な媒体をLINKSが丁寧に作成します。
              </p>
            </div>
          </div>
        )}

        <div ref={ref} className="details p-4">
          {inView && (
            <div className="animate__animated animate__fadeInUp">
              <h4 className="text-lg font-semibold">サービス説明</h4>
              <div>
                <p className="font-bold border-b-2 border-white mt-8">
                  フルサポート
                </p>
                <p className="p-2">
                  企画から制作、公開、運用保守まで一括で行います。
                  <br />
                  社外のWeb担当チームとして制作
                </p>
                <p className="font-bold border-b-2 border-white mt-8">
                  コーディングサポート
                </p>
                <p className="p-2">
                  コーディングのみLINKSで行うことも可能です。
                  <br />
                  コーダーのリソース不足の制作会社様向けの制作支援
                </p>
              </div>
            </div>
          )}
        </div>
        <div ref={ref} className="details p-4">
          {inView && (
            <div className="animate__animated animate__fadeInUp">
              <h4 className="text-lg font-semibold">使用技術</h4>
              <div>
                <p className="font-bold border-b-2 border-white mt-8">
                  対応可能言語等
                </p>
                <ul className="p-2">
                  <li>HTML/CSS</li>
                  <li>SASS</li>
                  <li>JavaScript</li>
                  <li>jQuery</li>
                  <li>php</li>
                </ul>
                <p className="font-bold border-b-2 border-white mt-8">CMS</p>
                <h4 className="mt-2">・Wordpress</h4>
                <p className="p-2">
                  オリジナルテーマの作成からカスタマイズまで、幅広く対応可能です。
                </p>
                <h4 className="mt-2">・STUDIO</h4>
                <p className="p-2">
                  国内でもシェアを広げているノーコード制作ツールのSTUDIOを活用した作成が可能です
                </p>
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="container mx-auto py-16 px-4">
        <div className="title">
          <h3 className="text-2xl font-semibold mb-2">
            Webアプリケーション開発
          </h3>
          <p>Web Development</p>
        </div>
        <div
          ref={ref}
          className="flex flex-col sm:flex-row justify-center items-center my-8"
        >
          {inView && (
            <div className="image_area w-[100%] sm:w-1/2 sm:mr-[-20px] animate__animated animate__fadeInLeft">
              <img src="./img/develop.jpg" alt="" />
            </div>
          )}
          {inView && (
            <div className="message_area w-[100%] sm:w-1/2 p-4 sm:ml-[-20px] animate__animated animate__fadeInRight">
              <p className="p-4 bg-black">
                社内Webシステムをはじめとする、Webアプリケーションを開発します。
                <br />
                事業アイディアを形にするパートをLINKSが担います。
                <br />
                要件定義から技術選定、実際の開発まで幅広く対応可能です。
              </p>
            </div>
          )}
        </div>
        <div ref={ref} className="details p-4">
          {inView && (
            <div className="animate__animated animate__fadeInUp">
              <h4 className="text-lg font-semibold">サービス説明</h4>
              <div>
                <p className="font-bold border-b-2 border-white mt-8">
                  フルサポート
                </p>
                <p className="p-2">
                  プロジェクトの初期段階から社外の開発チームとして参画いたします。
                  <br />
                  要件定義、設計、開発、改修などより良いシステムを構築するために一括でサポート
                </p>
                <p className="font-bold border-b-2 border-white mt-8">
                  開発サポート
                </p>
                <p className="p-2">
                  実装部分のみLINKSで担当いたします。
                  <br />
                  Webエンジニアのリソース不足の制作会社様向けの開発支援
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="details p-4">
          <h4 className="text-lg font-semibold">使用言語・フレームワーク等</h4>
          <div>
            <p className="font-bold border-b-2 border-white mt-8">
              対応可能言語等
            </p>
            <ul className="p-2">
              <li>React/Typscript</li>
              <li>Next.js</li>
              <li>Vue.js</li>
              <li>AWS</li>
              <li>GraphQL</li>
              <li>MySQL</li>
            </ul>
          </div>
        </div>
      </section>
      <Contact />
    </div>
  );
};

export default Service;
