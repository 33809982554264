import React from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "animate.css";

const Service: React.FC = () => {
  const { ref, inView } = useInView({
    // オプション
    rootMargin: "-100px", // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });

  return (
    <div className="text-white py-16">
      <div className="flex flex-col sm:flex-row-reverse justify-center items-center">
        <div className="image_area w-[100%] sm:w-1/2 sm:ml-[-20px]">
          <img src="./img/Top3.jpg" alt="" />
        </div>
        <div
          ref={ref}
          className="message_area w-[100%] sm:w-1/2 p-4 sm:mr-[-20px] z-10"
        >
          {inView && (
            <div className="animate__animated animate__fadeInLeft">
              <h2 className="text-4xl py-2 px-4 border-l-8 border-black text-black font-bold mb-8">
                Service
                <br />
                <span className="text-sm">事業内容</span>
              </h2>
              <div className="p-4 bg-black">
                <p className="mb-4">
                  これまで培ってきたWeb制作ノウハウ、デザイン技術、SNSやリスティング広告などを駆使して、クライアントのビジネス課題に対するソリューションを提供します。
                  <br />
                  Web制作をはじめ、アプリケーション開発、動画制作などを行なっております。
                </p>
                <Link
                  to={`/service`}
                  className="block w-[200px] text-center py-2 px-4 mx-auto text-white border-2 rounded-sm border-white hover:text-black hover:bg-white"
                >
                  Read more...
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Service;
