import React from "react";
import { useInView } from "react-intersection-observer";
import "animate.css";

const About: React.FC = () => {
  const { ref, inView } = useInView({
    // オプション
    rootMargin: "-100px", // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });
  return (
    <div className="text-white py-16">
      <div className="flex flex-col sm:flex-row justify-center items-center">
        <div className="image_area w-[100%] sm:w-1/2 sm:mr-[-20px]">
          <img src="./img/Top2.jpg" alt="" />
        </div>
        <div
          ref={ref}
          className="message_area w-[100%] sm:w-1/2 p-4 sm:ml-[-20px]"
        >
          {inView && (
            <div className="animate__animated animate__fadeInRight">
              <h2 className="text-4xl py-2 px-4 border-l-8 border-black text-black font-bold mb-8">
                About
                <br />
                <span className="text-sm">コンセプト</span>
              </h2>
              <p className="p-4 bg-black">
                難しいことをシンプルに伝える。 見る人が興味を持つ。
                <br />
                常にクライアント様の立場に立ち、問題解決の手段としてWebや広告、クリエイティブをご提案しております。
                <br />
                「社外の広報担当チーム」となり、これまでWebや広報に力を入れてくることができなかったところに私たちのチームが社員かのように伴走することで、共に苦労し、喜びを分かち合っていく。
                <br />
                そんな会社を目指しています。
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
