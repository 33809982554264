import React from "react";
import Contact from "../components/Top/Contact";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

const Company: React.FC = () => {
  return (
    <div className="bg-black min-h-[90vh] text-white">
      <ScrollToTopOnMount />
      <div className="page-fv h-[35vh] bg-company bg-no-repeat bg-center bg-cover">
        <div className="w-[100%] h-[100%] bg-black bg-opacity-80">
          <div className="flex flex-col justify-center items-center h-[100%]">
            <p className="text-4xl font-bold mb-4">Company</p>
            <h2>会社概要</h2>
          </div>
        </div>
      </div>
      <section className="container mx-auto py-16 px-4">
        <div className="title">
          <h3 className="text-2xl font-semibold mb-2">代表挨拶</h3>
          <p>浦野崇朗（Takaaki Urano）</p>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center my-8">
          <div className="image_area w-[100%] sm:w-1/2 sm:mr-[-20px]">
            <img src="./img/ceo.jpg" alt="" />
          </div>
          <div className="message_area w-[100%] sm:w-1/2 p-4 sm:ml-[-20px]">
            <p className="p-4 bg-black">
              制作・開発においては「目的」を明確にすることを大切にしています。
              <br />
              なぜそれを作るのか。何をゴールに設定するのか。
              <br />
              常にこれらを考えながら最善の手段を模索し、提案することが私たち開発会社としてやるべきことだと考えています。
              <br />
              「やるからには"ベスト"を目指す」をモットーに制作を行います。
              <br />
              また、開発を進める上で円滑なコミュニケーションを心がけ、双方に仕事がしやすい環境を目指しています。
              <br />
              クライアント、開発チームが気持ちよく同じゴールを目指せるような会社づくりを目指します。
            </p>
          </div>
        </div>
      </section>
      <section className="container mx-auto py-16 px-4">
        <div className="title">
          <h3 className="text-2xl font-semibold mb-2">会社概要</h3>
          <p>OverView</p>
        </div>
        <div className="details p-4">
          <div>
            <p className="font-bold border-b border-dotted border-white mt-8">
              法人名
            </p>
            <p className="p-2">合同会社LINKS（LINKS LLC.）</p>
            <p className="font-bold border-b border-dotted border-white mt-8">
              所在地
            </p>
            <p className="p-2">千葉県野田市中里532</p>
            <p className="font-bold border-b border-dotted border-white mt-8">
              設立
            </p>
            <p className="p-2">2016年10月</p>
            <p className="font-bold border-b border-dotted border-white mt-8">
              資本金
            </p>
            <p className="p-2">1,000,000円</p>
            <p className="font-bold border-b border-dotted border-white mt-8">
              事業内容
            </p>
            <p className="p-2">
              Web制作・開発
              <br />
              Webマーケティング
              <br />
              クリエイティブデザイン
              <br />
              動画編集
            </p>
          </div>
        </div>
      </section>
      <Contact />
    </div>
  );
};

export default Company;
