import React, { useState } from "react";
import { init, send } from "emailjs-com";

const Form: React.FC = () => {
  const [name, setName] = useState(""); // 「ご氏名」の部分
  const [company, setCompany] = useState(""); // 「会社名」の部分
  const [mail, setMail] = useState(""); // 「メールアドレス」の部分
  const [tel, setTel] = useState(""); // 「電話」の部分
  const [message, setMessage] = useState(""); // 「お問い合わせ内容」の部分

  const sendMail = async () => {
    const userID = process.env.REACT_APP_MAILUSER_ID;
    const serviceID = process.env.REACT_APP_MAILSERVICE_ID;
    const templateID = process.env.REACT_APP_MAILTEMPLATE_ID;

    if (
      userID !== undefined &&
      serviceID !== undefined &&
      templateID !== undefined
    ) {
      init(userID);

      const template_param = {
        name: name,
        company: company,
        mail: mail,
        tel: tel,
        message: message,
      };

      await send(serviceID, templateID, template_param).then(() => {
        window.alert("お問い合わせを送信しました。");

        setName("");
        setCompany("");
        setMail("");
        setMessage("");
        setTel("");
      });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    sendMail();
  };

  return (
    <div className="container mx-auto my-4 p-4">
      <form className="w-[95%] max-w-[600px] mx-auto">
        <div className="mb-4">
          <label htmlFor="" className="mr-4 mb-2 block">
            お名前（必須）
          </label>
          <input
            type="text"
            placeholder="お名前"
            className="rounded-sm p-2 text-black w-[100%]"
            required
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="" className="mr-4 mb-2 block">
            会社名
          </label>
          <input
            type="text"
            placeholder="会社名"
            className="rounded-sm p-2 text-black w-[100%]"
            value={company}
            onChange={(event) => setCompany(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="" className="mr-4 mb-2 block">
            メール（必須）
          </label>
          <input
            type="text"
            placeholder="メールアドレス"
            className="rounded-sm p-2 text-black w-[100%]"
            required
            value={mail}
            onChange={(event) => setMail(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="" className="mr-4 mb-2 block">
            電話
          </label>
          <input
            type="text"
            placeholder="電話番号"
            className="rounded-sm p-2 text-black w-[100%]"
            value={tel}
            onChange={(event) => setTel(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="" className="mr-4 mb-2 block">
            お問合せ内容（必須）
          </label>
          <textarea
            placeholder="お問合せ内容"
            className="rounded-sm p-2 text-black w-[100%] h-[250px]"
            required
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          ></textarea>
        </div>
        <div className="submit">
          <button
            onClick={handleClick}
            className="block w-[200px] text-center py-2 px-4 mx-auto text-white border-2 rounded-sm border-white hover:text-black hover:bg-white"
          >
            送信する
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
