import React from "react";
import { MdArrowDropDown } from "react-icons/md";

const Fv: React.FC = () => {
  return (
    <section className="bg-fv bg-no-repeat bg-center bg-cover text-white drop-shadow-lg">
      <div className="w-[100%] h-[100%] bg-black bg-opacity-80">
        <div className="flex flex-col justify-center items-center py-24">
          <div className="title text-center">
            <h2 className="text-3xl font-bold mb-4">Be Creative</h2>
            <p>クリエイティブで事業を後押し</p>
          </div>
          <h1 className="mb-4">合同会社LINKS</h1>
          <div className="h-[20vh] w-[2px] bg-white"></div>
          <MdArrowDropDown className="text-4xl" />
          <MdArrowDropDown className="text-4xl mt-[-20px]" />
        </div>
      </div>
    </section>
  );
};

export default Fv;
