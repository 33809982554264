import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Company from "./pages/Company";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
import Top from "./pages/Top";
import Privacy from "./pages/Privacy";

const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <header>
          <Header />
        </header>
        <main className="min-h-[85vh]">
          <Routes>
            <Route index element={<Top />} />
            <Route path="/service" element={<Service />} />
            <Route path="/company" element={<Company />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
      </BrowserRouter>
    </div>
  );
};

export default App;
