import React from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { Link } from "react-router-dom";

const Company: React.FC = () => {
  return (
    <div className="bg-company bg-no-repeat bg-center bg-cover">
      <div className="w-[100%] h-[100%] bg-black bg-opacity-80">
        <div className="container flex flex-col sm:flex-row sm:items-center sm:justify-center text-white drop-shadow-lg py-16 px-8 mx-auto">
          <div className="title">
            <h2 className="text-4xl py-2 px-4 border-l-8 border-white font-bold mb-8">
              Company
              <br />
              <span className="text-sm">会社概要</span>
            </h2>
          </div>
          <div className="flex items-center mb-2 w-min">
            <div className="line bg-white w-[150px] h-[2px]"></div>
            <IoMdArrowDropright className="block text-4xl" />
          </div>
          <Link
            to={`/company`}
            className="block w-[200px] ml-auto sm:mx-0 text-center py-2 px-4 text-white border-2 rounded-sm border-white hover:text-black hover:bg-white"
          >
            Read more...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Company;
