import React from "react";
import { IoMdArrowDropleft } from "react-icons/io";
import { Link } from "react-router-dom";

const Contact: React.FC = () => {
  return (
    <div className="bg-contact bg-no-repeat bg-center bg-cover">
      <div className="w-[100%] h-[100%] bg-black bg-opacity-80">
        <div className="container flex flex-col sm:flex-row-reverse items-end sm:items-center justify-center text-white py-16 px-8 mx-auto">
          <div className="title">
            <h2 className="text-4xl py-2 px-4 border-l-8 border-white font-bold mb-8">
              Contact
              <br />
              <span className="text-sm">お問合せ</span>
            </h2>
          </div>
          <div className="flex flex-row-reverse items-center">
            <div className="line bg-white w-[150px] h-[2px] mr-2"></div>
            <IoMdArrowDropleft className="text-4xl" />
          </div>
          <Link
            to={`/contact`}
            className="block w-[200px] text-center py-2 px-4 mr-auto sm:mx-0 text-white border-2 rounded-sm border-white hover:text-black hover:bg-white"
          >
            Contact us...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contact;
